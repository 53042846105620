<template>
  <div>

    <a-card title="权限配置" size="small">
      <a-card size="small" hoverable style="margin-top:10px">
        <h3 style="font-weight: bold">菜单配置</h3>
        <a-card-meta description="生成初始化菜单，与权限一一对应">
        </a-card-meta>
        <p style="margin-top:10px;">
          <a-button type="primary" @click="initMenus">初始化菜单</a-button>
        </p>
      </a-card>
      <!-- <a-card size="small" hoverable style="margin-top:10px">
        <h3 style="font-weight: bold">权限配置</h3>
        <a-card-meta  description="初始化权限，控制左侧菜单显示">
        </a-card-meta>
        <p style="margin-top:10px;">
          <a-button type="primary" @click="initPermission">权限配置</a-button>
        </p>
      </a-card> -->
      <a-card size="small" hoverable style="margin-top:10px">
        <h3 style="font-weight: bold">主题设置</h3>
        <a-card-meta description="主题设置，根据需要调整左侧菜单明暗">
        </a-card-meta>
        <p style="margin-top:10px;">
          <a-button type="primary" @click="changeTheme">主题设置</a-button>
        </p>

      </a-card>
      <a-card size="small" hoverable style="margin-top:10px;background-color: aquamarine;">
        <h3 style="font-weight: bold">领导设置</h3>
        <a-card-meta description="配置乡领导（书记、乡长）,小程序端可查看举报信息">
        </a-card-meta>
        <p style="margin-top:10px;">
          <a-button type="danger" @click="setLeader">设置领导</a-button>
        </p>
      </a-card>
    </a-card>
    <!-- 设置村干部 -->
    <a-drawer title="设置领导" :width="600" placement="right" :closable="false" :visible="visible_wxuser"
      :after-visible-change="afterVisibleChange" @close="onClose">
      <a-form-model :model="currWxuser" labelAlign="left">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="currWxuser.name" placeholder="请输入名称关键字" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input v-model="currWxuser.mobile" placeholder="请输入手机号关键字" />
        </a-form-model-item>
        <a-form-model-item label="昵称" prop="nickName">
          <a-input v-model="currWxuser.nickName" placeholder="请输入昵称关键字" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button-group>
            <a-button @click="getWxusers" type="primary">
              搜索
            </a-button>
            <a-button @click="clearWxuser">
              清空
            </a-button>
          </a-button-group>

        </a-form-model-item>
      </a-form-model>
      <a-table size="small" :data-source="wxusers.data" :columns="wxcolumns" rowKey="id" bordered :pagination="false">
        <template slot="action" slot-scope="text,record">
          <a-button-group>
            <a-button size="small" v-if="record.is_leader == 0" icon="safety-certificate" type="primary"
              @click="submitLeader(record)">
              设置领导
            </a-button>
            <a-button size="small" v-else icon="safety-certificate" type="danger" @click="submitLeader(record)">
              取消设置
            </a-button>
          </a-button-group>
        </template>
      </a-table>
      <a-pagination style="margin-top:10px;" :total="wxusers.total" :show-total="total => `共 ${total} 条`"
        v-if="wxusers.total > wxusers.per_page" :defaultCurrent='1' :pageSize="wxusers.per_page" @change="getWxusers">
      </a-pagination>
    </a-drawer>
  </div>
</template>

<script>
const wxcolumns = [
  {
    title: 'id',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: "手机号",
    key: "mobile",
    dataIndex: "mobile",
  },
  {
    title: "昵称",
    key: "nickName",
    dataIndex: "nickName",
  },
  {
    title: '操作',
    key: 'action',
    width: 150,
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  data() {
    return {
      isAdmin: false,
      visible_wxuser: false,
      currWxuser: {
        name: "",
        nickName: '',
        mobile: ''
      },
      wxusers: [],
      wxcolumns
    }
  },
  created() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user.id == 1) {
      this.isAdmin = true
    }
  },
  methods: {
    afterVisibleChange() { },
    async initMenus() {
      await this.axios.post('admin/menus')
    },
    async clearWxuser() {
      this.currWxuser = {
        name: "",
        nickName: '',
        mobile: ''
      }
      await this.getWxusers()
    },

    onClose() {
      this.visible_wxuser = false
    },
    async submitLeader(record) {
      console.log(record)
      await this.axios.post(`admin/wxuser/${record.id}/setLeader`)
      await this.getWxusers()
    },
    async initPermission() {
      await this.axios.post('admin/permission/init')
    },
    press() {
      this.$message.success('长按了')
    },
    changeTheme() {
      //获取state中的主题
      let theme = this.$store.state.theme
      //切换主题
      if (theme == 'dark') {
        this.$store.commit('theme', 'light')
      } else {
        this.$store.commit('theme', 'dark')
      }
    },
    setLeader() {
      this.visible_wxuser = true;
      this.getWxusers()
    },
    async getWxusers(page = 1) {
      this.wxusers =
        await this.axios.get(`admin/wxuser?name=${this.currWxuser.name}&nickName=${this.currWxuser.nickName}&mobile=${this.currWxuser.mobile}&page=${page}`)
    },
  }
}
</script>

<style scoped>
.parent {
  position: absolute;
  top: 0;
}

.drag {
  width: 100px;
  height: 100px;
  background: red;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
