<template>
    <div>
        <a-card title="政策新增">
            <a-form-model :rules="rules" ref="ruleForm" layout="vertical" :model="form">
                <a-form-model-item label="标题"  prop="title">
                    <a-input v-model="form.title" placeholder="标题" />
                </a-form-model-item>
                <a-form-model-item label="封面" prop="preview">
                    <Upload v-model="form.preview" />
                </a-form-model-item>
                <a-form-model-item label="内容" prop="content">
                    <WangEditor :content="form.content" @changeContent="changeContent" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" @click="onSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </a-card>
    </div>
</template>
<script>
import Upload from '@/components/Upload/index.vue'
import WangEditor from '@/components/Editor/WangEditor.vue';
const rules = {
    title: [
        { required: true, message: '标题不能为空', trigger: 'change' },
    ],
    preview: [{ required: true, message: '请上传封面', trigger: 'change' },],
    content: [{ required: true, min: 6, message: '请填写内容', trigger: 'change' },],
}
export default {
    components: { WangEditor, Upload },
    data() {
        return {
            rules,
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            form: {
                title: "",
                preview: "",
                content: ''
            },
        };
    },
    methods: {
        async onSubmit() {
            console.log(this.form)
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    await this.axios.post('admin/policy', this.form).then(_ => {
                        this.$router.push('/admin/policy/index')
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(content) {
            this.form.content = content
        }
    },
};
</script>
  