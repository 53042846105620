<template>
    <div>
        <a-card>
            <a-form layout="inline">
                <a-form-item label="类别">
                    <a-input v-model="category" placeholder="请输入类别关键字" />
                </a-form-item>
                <a-form-item label="详情">
                    <a-input v-model="detail" placeholder="请输入详情关键字" />
                </a-form-item>
                <a-form-item label="状态">
                    <a-select style="width: 120px" :default-value="state" v-model="state">
                        <a-select-option value="true">
                            已处理
                        </a-select-option>
                        <a-select-option value="false">
                            未处理
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button-group>
                        <a-button @click="getReports" type="primary">
                            搜索
                        </a-button>
                        <a-button @click="clearAndgetReports">
                            清空
                        </a-button>
                    </a-button-group>

                </a-form-item>
            </a-form>
        </a-card>
        <a-table size="middle" :data-source="reports.data" :columns="columns" rowKey="id" bordered :pagination="false">
            <template slot="status" slot-scope="text,record">
                <a-button-group>
                    <a-icon :style="{ fontSize: '18px',color:'#52c419'}" type="check-circle" two-tone-color="#52c41a"
                        theme="filled" v-if="record.state==1" />
                    <a-icon :style="{ fontSize: '18px',color:'#f8ad13'}" type="info-circle" two-tone-color="#ffb142"
                        theme="filled" v-else />
                </a-button-group>
            </template>
            <template slot="detail" slot-scope="text,record">
                {{record.detail | truncate(24)}}
            </template>
            <template slot="action" slot-scope="text,record">
                <a-button-group>
                    <a-button v-if="record.state==false" size="small" type="primary" @click="handleFinish(record)">
                        <a-icon type="form" />
                        处理
                    </a-button>
                    <a-button v-else size="small" type="primary" @click="showReport(record)">
                        <a-icon type="unordered-list" />
                        详情
                    </a-button>
                </a-button-group>
            </template>
        </a-table>
        <a-pagination style="margin-top:10px;" :total="reports.total" :show-total="total => `共 ${total} 条`"
            v-if="reports.total>reports.per_page" :defaultCurrent='1' :pageSize="reports.per_page" @change="getReports">
        </a-pagination>

        <!-- 处理页 -->
        <a-modal v-model="visible" :width="900" title="处理回复" ok-text="确认" cancel-text="取消" @cancel="onCancel"
            @ok="submit">
            <a-descriptions bordered title="举报" size="small" :column="3">
                <a-descriptions-item label="行政村">
                    {{currReport.village_name}}
                </a-descriptions-item>

                <a-descriptions-item label="姓名">
                    {{currReport.massname}}

                </a-descriptions-item>
                <a-descriptions-item label="电话">
                    {{currReport.massphone}}
                </a-descriptions-item>
                <a-descriptions-item label="咨询类别">
                    {{currReport.category}}
                </a-descriptions-item>
                <a-descriptions-item label="时间">
                    {{currReport.created_at}}
                </a-descriptions-item>
                <a-descriptions-item label="详情" span="3">
                    {{currReport.detail}}
                </a-descriptions-item>
                <a-descriptions-item label="照片">
                    <img style="max-height:300px;text-align: center;" v-for="(img,index) in currReport.images"
                        :key="index" :src="img.path" alt="" />
                </a-descriptions-item>

            </a-descriptions>
            <a-divider>处理回复 </a-divider>
            <a-form-model :dialog-style="{ top: '0px' }" ref="subForm" :rules="rules" layout="vertical" :model="subForm">
                <a-form-model-item label="回复内容" prop="content">
                    <a-textarea v-model="subForm.content" placeholder="请填写回复内容"
                        :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label="附件" prop="attachments">
                    <UploadMultiple :images="subForm.attachments" fieldName="attachments" @uploadFile="uploadFile" />
                </a-form-model-item>
                <a-form-model-item label="备注信息" prop="remark">
                    <a-input v-model="subForm.remark" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!-- 查看页 -->
        <a-modal v-model="show" :width="900" :footer="null">
            <a-descriptions bordered title="信息" size="small" :column="3">
                <a-descriptions-item label="行政村">
                    {{currReport.village_id}}
                </a-descriptions-item>
                <a-descriptions-item label="标题">
                    {{currReport.title}}
                </a-descriptions-item>

                <a-descriptions-item label="姓名">
                    {{currReport.massname}}

                </a-descriptions-item>
                <a-descriptions-item label="电话">
                    {{currReport.massphone}}
                </a-descriptions-item>
                <a-descriptions-item label="咨询类别">
                    {{currReport.category}}
                </a-descriptions-item>
                <a-descriptions-item label="时间">
                    {{currReport.created_at}}
                </a-descriptions-item>
                <a-descriptions-item label="详情" span="3">
                    {{currReport.detail}}
                </a-descriptions-item>
                <a-descriptions-item label="照片">
                    <a v-for="(img,index) in currReport.images" :key="index">
                        <img :src="img" alt="" target="_blank" style="max-width:80px;" />
                    </a>
                </a-descriptions-item>

            </a-descriptions>
            <a-divider>回复建议 </a-divider>
            <a-form-model size="small" :dialog-style="{ top: '0px' }" ref="replyForm" layout="vertical"
                :model="replyForm">
                <a-form-model-item label="回复内容" prop="content">
                    <a-textarea disabled v-model="replyForm.content" placeholder="请填写回复内容"
                        :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label="附件" prop="attachments">
                    <div v-for="(imgdata,index) in replyForm.attachments" :key="index">
                        <FilePreview :extension="imgdata.extension" :path="imgdata.path" :name="imgdata.name" />
                    </div>
                </a-form-model-item>
                <a-form-model-item label="备注信息" prop="remark">
                    <a-input disabled v-model="replyForm.remark" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>
<script>
import UploadMultiple from '#/components/UploadMultiple'
import FilePreview from '#/components/FilePreview'
const columns = [
    {
        title: 'id',
        key: 'id',
        dataIndex: 'id',
    },
    {
        title: '行政村',
        key: 'village_name',
        dataIndex: 'village_name',
        scopedSlots: { customRender: 'village' },   
    },
    {
        title: '咨询类别',
        dataIndex: 'category',
        key: 'category',
        width: 100
    },
    {
        title: '详情',
        key: 'detail',
        dataIndex: 'detail',
        scopedSlots: { customRender: 'detail' },
    },
    {
        dataIndex: 'massname',
        key: 'massname',
        title: "姓名",
        width: 150
    },
    {
        title: '电话',
        dataIndex: 'massphone',
        key: 'massphone',
        width: 200
    },

    {
        title: '状态',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        scopedSlots: { customRender: 'status' },
    },
    {
        title: '创建时间',
        key: 'created_at',
        dataIndex: 'created_at',
        align: 'center',
        scopedSlots: { customRender: 'createTime' },
    },
    {
        title: '处理时间',
        key: 'updated_at',
        dataIndex: 'updated_at',
        align: 'center',
    },
    {
        title: '处理',
        key: 'action',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
];
const rules = {
    content: [
        { required: true, message: '请输入内容', trigger: 'change' },
    ],
}

export default {
    components: { UploadMultiple, FilePreview },
    data() {
        return {
            rules,
            reports: {},
            columns,
            currReport: {},
            category: '',
            detail: '',
            state: 'false',
            visible: false,
            subForm: { content:"",remark:"",attachments: [], wxuser_id: null },
            show: false,
            replyForm: {}
        };
    },
    created() {
        this.getReports()
    },
    methods: {
        handleFinish(record) {
            this.visible = true
            this.currReport = record
            this.subForm.wxuser_id = record.wxuser_id
        },
        uploadFile(fileArray) {
            console.log("====父级接收到的对象====", fileArray)
            let resArray = fileArray.map(item => {
                return {
                    id: item.response.id,
                    path: item.response.path,
                    extension: item.response.extension,
                    name: item.response.name
                }
            })
            this.subForm.attachments = resArray
        },
        async showReport(record) {
            this.show = true
            this.currReport = record
            this.replyForm = await this.axios.get(`admin/report/${this.currReport.id}/reply`)
            console.log(this.replyForm)
        },
        async submit() {
            //提交数据
            console.log(this.subForm)
            this.$refs.subForm.validate(async valid => {
                if (valid) {
                    await this.axios
                        .post(`admin/report/${this.currReport.id}/reply`, this.subForm).then(_ => {
                            this.$refs.subForm.resetFields();
                            this.visible = false
                            this.getReports()
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        async clearAndgetReports() {
            this.category = '';
            this.detail = '';
            this.state = "false";
            await this.getReports()
        },
        onCancel() {
            this.$refs.subForm.resetFields();
            this.currReport = {}
        },
        async getReports(page = 1) {
            this.reports =
                await this.axios.get(`admin/report?category=${this.category}&detail=${this.detail}&state=${this.state}&page=${page}`)
        },
    }
};
</script>
  