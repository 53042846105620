<template>
  <div>
    <a-card>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <a-form layout="inline">
          <a-form-item label="名称">
            <a-input v-model="name" placeholder="请输入标题关键字" />
          </a-form-item>

          <a-form-item>
            <a-button-group>
              <a-button @click="getVillages" type="primary"> 搜索 </a-button>
              <a-button @click="clearAndgetVillages"> 清空 </a-button>
            </a-button-group>
          </a-form-item>
        </a-form>
        <a-button type="primary" icon="plus" @click="addVillage">添加</a-button>
      </div>
    </a-card>
    <a-table
      size="middle"
      :data-source="villages.data"
      :columns="columns"
      rowKey="id"
      bordered
      :pagination="false"
    >
      <template slot="admins" slot-scope="text, record">
        <div class="avatar">
          <div v-for="(admin, index) in record.admins" :key="index">
            <div class="avatar_item">
              <a-popover>
                <template slot="content">
                  <a-avatar :size="120" :src="admin.avatarUrl" />
                </template>
                <a-avatar :src="admin.avatarUrl" />
              </a-popover>
              <span
                style="font-size: 12px; width: 100px; word-wrap: break-word"
                >{{ admin.nickName }}</span
              >
              <a-popconfirm
                title="确定取消吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="cancelAdmin(record.id, admin.wxuser_id)"
              >
                <a-button size="small" ghost type="danger"> 取消干部</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
      </template>

      <template slot="action" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="handleEdit(record)">
            <a-icon type="form" />
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除吗？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDel(record)"
          >
            <a-button icon="delete" type="danger"> 删除 </a-button>
          </a-popconfirm>
          <a-button icon="edit" type="primary" @click="setAdmin(record)">
            设置干部
          </a-button>
        </a-button-group>
      </template>
    </a-table>
    <a-pagination
      style="margin-top: 10px"
      :total="villages.total"
      :show-total="(total) => `共 ${total} 条`"
      v-if="villages.total > villages.per_page"
      :defaultCurrent="1"
      :pageSize="villages.per_page"
      @change="getVillages"
    >
    </a-pagination>

    <!-- 编辑页 -->
    <a-modal
      v-model="visible"
      :width="600"
      title="行政村管理"
      ok-text="确认"
      cancel-text="取消"
      @cancel="onCancel"
      @ok="submit"
    >
      <a-form-model
        :dialog-style="{ top: '0px' }"
        :rules="rules"
        ref="ruleForm"
        layout="vertical"
        :model="subForm"
      >
        <a-form-model-item label="行政村名称" prop="name">
          <a-input
            v-model="subForm.name"
            placeholder="请填写行政村名称"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
        <a-form-model-item label="行政村编码" prop="vid">
          <a-input
            v-model="subForm.vid"
            placeholder="请填写行政村名称ID"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 设置村干部 -->
    <a-drawer
      :title="`设置【${this.setAdminTitle}】干部`"
      :width="600"
      placement="right"
      :closable="false"
      :visible="visible_wxuser"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <a-form-model :model="currWxuser" labelAlign="left">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="currWxuser.name" placeholder="请输入名称关键字" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input
            v-model="currWxuser.mobile"
            placeholder="请输入手机号关键字"
          />
        </a-form-model-item>
        <a-form-model-item label="昵称" prop="nickName">
          <a-input
            v-model="currWxuser.nickName"
            placeholder="请输入昵称关键字"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button-group>
            <a-button @click="getWxusers" type="primary"> 搜索 </a-button>
            <a-button @click="clearWxuser"> 清空 </a-button>
          </a-button-group>
        </a-form-model-item>
      </a-form-model>
      <a-table
        size="small"
        :data-source="wxusers.data"
        :columns="wxcolumns"
        rowKey="id"
        bordered
        :pagination="false"
      >
        <template slot="action" slot-scope="text, record">
          <a-button-group>
            <a-button
              size="small"
              icon="safety-certificate"
              type="primary"
              @click="subSetAdmin(record)"
            >
              设置干部
            </a-button>
          </a-button-group>
        </template>
      </a-table>
      <a-pagination
        style="margin-top: 10px"
        :total="wxusers.total"
        :show-total="(total) => `共 ${total} 条`"
        v-if="wxusers.total > wxusers.per_page"
        :defaultCurrent="1"
        :pageSize="wxusers.per_page"
        @change="getWxusers"
      >
      </a-pagination>
    </a-drawer>
  </div>
</template>
<script>
const columns = [
  {
    title: "id",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "行政村编码",
    key: "vid",
    width: 200,
    dataIndex: "vid",
    scopedSlots: { customRender: "detail" },
  },
  {
    dataIndex: "name",
    key: "name",
    title: "行政村名称",
    width: 150,
  },
  {
    dataIndex: "admins",
    key: "admins",
    title: "村干部",
    scopedSlots: { customRender: "admins" },
  },
  {
    title: "创建时间",
    key: "created_at",
    dataIndex: "created_at",
    width: 150,
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    width: 150,
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
const wxcolumns = [
  {
    title: "id",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "姓名",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "手机号",
    key: "mobile",
    dataIndex: "mobile",
  },
  {
    title: "昵称",
    key: "nickName",
    dataIndex: "nickName",
  },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
const rules = {
  name: [{ required: true, message: "请输入内容", trigger: "change" }],
  vid: [{ required: true, message: "请输入内容", trigger: "change" }],
};

export default {
  data() {
    return {
      rules,
      villages: {},
      columns,
      wxcolumns,
      visible_wxuser: false,
      name: "",
      subForm: {},
      visible: false,
      form: {},
      setAdminTitle: "",
      method: "post",
      wxusers: {},
      currWxuser: {
        vid: "",
        name: "",
        mobile: "",
        nickName: "",
      },
      currPage: 1,
    };
  },
  created() {
    this.getVillages();
    this.getWxusers();
  },
  methods: {
    async submit() {
      //提交数据
      console.log(this.subForm);
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let url = "";
          if (this.method == "put") {
            url = `admin/village/${this.subForm.id}`;
          } else {
            url = `admin/village`;
          }
          await this.axios[this.method](url, this.subForm).then((_) => {
            this.visible = false;
            this.getVillages(1);
            this.$refs.ruleForm.resetFields();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async clearAndgetVillages() {
      this.name = "";
      await this.getVillages();
    },
    onCancel() {
      console.log("onCancel=========");
      this.currVillage = {};
    },
    async getVillages(page = 1) {
      this.currPage = page;
      console.log("this.currPage", this.currPage);
      if (this.currPage !== 1) {
        this.villages = await this.axios.get(
          `admin/village?name=${this.name}&page=${this.currPage}`
        );
      } else {
        this.villages = await this.axios.get(
          `admin/village?name=${this.name}&page=${page}`
        );
      }
    },
    async handleEdit(record) {
      this.visible = true;
      this.method = "put";
      this.$set(this.subForm, "id", record.id);
      this.$set(this.subForm, "vid", record.vid);
      this.$set(this.subForm, "name", record.name);
    },
    async handleDel(record) {
      this.axios.delete(`admin/village/${record.id}`).then((_) => {
        this.getVillages();
      });
    },
    addVillage() {
      this.method = "post";
      this.$set(this.subForm, "vid", null);
      this.$set(this.subForm, "name", null);
      this.visible = true;
    },
    onClose() {
      this.visible_wxuser = false;
    },
    afterVisibleChange() {},
    async getWxusers(page = 1) {
      this.wxusers = await this.axios.get(
        `admin/wxuser?name=${this.currWxuser.name}&nickName=${this.currWxuser.nickName}&mobile=${this.currWxuser.mobile}&page=${page}`
      );
    },
    async clearWxuser() {
      this.currWxuser.name = "";
      this.currWxuser.mobile = "";
      this.currWxuser.nickName = "";
      await this.getWxusers(this.currPage);
    },
    //打开设置弹窗
    async setAdmin(record) {
      this.$set(this.currWxuser, "name", "");
      this.$set(this.currWxuser, "mobile", "");
      this.$set(this.currWxuser, "nickName", "");
      this.visible_wxuser = true;
      this.setAdminTitle = record.name;
      // 设置表单行政村ID
      this.currWxuser.vid = record.id;
    },
    async subSetAdmin(wxuser) {
      await this.axios
        .post(
          `admin/village/${this.currWxuser.vid}/wxuser/${wxuser.id}/setAdmin`
        )
        .then((_) => {
          this.visible_wxuser = false;
          this.getVillages(this.currPage);
        });
    },
    async cancelAdmin(village_id, wxuser_id) {
      await this.axios
        .post(`admin/village/${village_id}/wxuser/${wxuser_id}/cancelAdmin`)
        .then((_) => {
          this.getVillages(this.currPage);
        });
    },
  },
};
</script>
  
<style scoped>
.avatar {
  display: flex;
  height: 80px;
}

.avatar_item {
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: #21ad17;
}
</style>