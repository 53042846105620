<template>
  <div>
    <a-card title="角色管理" size="small">
      <template #extra>
        <a-button type="primary" @click="roleVisible=true">添加角色</a-button>
      </template>
      <div style="padding:0 20px;">
        <a-list item-layout="horizontal" :data-source="roles" hoverable>
          <a-list-item slot="renderItem" slot-scope="item">
            <h3 style="font-weight: bold">{{ item.title }}</h3>
            <div>
              标识：{{ item.name }}
              --{{ item.description }}
            </div>
            <p>
              <a-button type="primary" style="margin-top:10px;" @click="setPermission(item)">设置权限</a-button>
              <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="deleteRole(item)">
                <a-button type="danger" style="margin-top:10px;">删除</a-button>
              </a-popconfirm>
            </p>
          </a-list-item>
        </a-list>
      </div>

    </a-card>
    <!--    模态框-->
    <a-modal v-model="visible" title="配置权限" ok-text="确认" cancel-text="取消" @cancel="onCancel" @ok="onSubmit">
      <a-tree checkable :multiple="true" @check="onCheck" :expanded-keys="expandedKeys"
        :auto-expand-parent="autoExpandParent" :checkedKeys="checkedKeys" :tree-data="permissions" @expand="onExpand" />
    </a-modal>
    <!--    模态框-->
    <a-modal v-model="roleVisible" title="角色管理" ok-text="确认" cancel-text="取消" @cancel="onRoleCancel" @ok="submitRole">
      <a-form-model ref="rForm" :rules="rules" layout="vertical" :model="roleForm">
        <a-form-model-item label="英文标识(英文)" prop="name">
          <a-input v-model="roleForm.name" />
        </a-form-model-item>
        <a-form-model-item label="中文简称" prop="title">
          <a-input v-model="roleForm.title" />
        </a-form-model-item>
        <a-form-model-item label="描述">
          <a-input type="textarea" v-model="roleForm.description" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const rules = {
  title: [
    { required: true, message: '中文标识不能为空', trigger: 'change' },
  ],
  name: [
    { required: true, message: '英文标识不能为空', trigger: 'change' },
  ]
}
export default {
  data() {
    return {
      rid: null,
      rules,
      roles: [],
      permissions: [],//本地存储的权限
      disabled: false,
      visible: false,
      roleVisible: false,
      //    treeData相关
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      reCheckKeys: [],
      subPermissions: [],
      roleForm: { name: '', title: '', description: '' },
    }
  },
  created() {
    this.getFullMenus()
    this.getRoles();
  },
  watch: {
    "roleForm.name"(newVal, oldVal) {
      this.roleForm.name = newVal.replace(/[^a-zA-Z]/g, '')
    }
  },
  methods: {
    async getFullMenus() {
      await this.axios.post("admin/fullMenu").then(_ => {
        let data = this.formatPermissions(_)
        this.permissions = this.filterMenus(data)
      })
    },
    //过滤去除后台路由中字段hidden=1的路由，
    filterMenus(menus) {
      const res = []
      menus.forEach(m => {
        const newItem = {};
        if (m.hidden != 1) {
          for (const key in m) {
            //这里有重复执行，但不影响最终效果
            newItem[key] = m[key]
          }
          if (newItem.children && newItem.children.length > 0) {
            newItem.children = this.filterMenus(m.children)
          }
          res.push(newItem)
        }
      })
      return res
    },
    async submitRole() {
      console.log(this.roleForm)
      this.$refs.rForm.validate(async valid => {
        if (valid) {
          await this.axios.post("admin/role", this.roleForm).then(res => {
            this.roleVisible = false;
            this.getRoles();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async deleteRole(role) {
      await this.axios.delete(`admin/role/${role.id}`).then(_ => {
        this.getRoles();
      })
    },
    onRoleCancel() {
      this.roleVisible = false
      //清空表单
      this.$set(this.roleForm,'name',"")
      this.$set(this.roleForm,'title',"")
      this.$set(this.roleForm,'description',"")
    },
    //获取所有角色
    async getRoles() {
      await this.axios.get('admin/role').then(res => {
        this.roles = res;
        if (this.roles.length > 0) {
          this.disabled = true;
        }
      })
    },
    //格式化本地状态中的permissions
    formatPermissions(permissions) {
      //转换为{value:'',label:'',name:'',children:[]}格式
      let result = [];
      permissions.forEach(item => {
        let obj = {
          value: item.name,
          title: item.meta.title,
          key: item.name,
          children: []
        };
        if (item.children && item.children.length > 0) {
          obj.children = this.formatPermissions(item.children);
        }
        result.push(obj);
      });
      return result;
    },
    //去设置权限
    setPermission(role) {
      this.reCheckKeys = [];
      this.rid = role.id
      this.axios.post(`admin/role/${role.id}/permissions`).then(_ => {
        // console.log("返回值===", _)
        this.checkedKeys = this.formatCheckedKeys(_)
        // console.log(this.reCheckKeys)

        this.visible = true;
      })
    }
    ,
    formatCheckedKeys(permissions) {
      //目标获取所有的key
      permissions.map(item => {
        if (item.children && item.children.length == 0) {
          this.reCheckKeys.push(item.name)
        }
        if (item.children && item.children.length > 0) {
          this.formatCheckedKeys(item.children)
        }
      });

      return this.reCheckKeys;
    }
    ,
    //    点击checkbox事件
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
      let parentNodes = new Set();
      //从checkedKeys中提取父级节点路径
      checkedKeys.forEach(item => {
        let path = item.split('.');
        if (path.length > 2) {
          path.pop();
          parentNodes.add(path.join('.'))
        }
      });
      checkedKeys.map(item => {
        parentNodes.add(item);
      })
      console.log(parentNodes)
      this.subPermissions = Array.from(parentNodes);
    }
    ,
    //    展开事件
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    }
    ,
    //    关闭事件
    onCancel() {
      this.visible = false;
      this.selectedKeys = []
    }
    ,
    //提交权限
    async onSubmit() {
      await this.axios.post(`admin/role/${this.rid}/syncpermissions`, { permission: this.subPermissions }).then(_ => {
        this.visible = false;
      })

    }
  }
}
</script>

<style scoped>

</style>
