<template>
    <div>
        <a-card>
            <div style="display:flex;justify-content:space-between;align-items: center;">
                <a-form layout="inline">
                    <a-form-item label="姓名">
                        <a-input v-model="name" placeholder="请输入姓名关键字" />
                    </a-form-item>
                    <a-form-item label="昵称">
                        <a-input v-model="nickName" placeholder="请输入昵称关键字" />
                    </a-form-item>
                    <a-form-item label="手机号">
                        <a-input v-model="mobile" placeholder="请输入手机号关键字" />
                    </a-form-item>
                    <a-form-item>
                        <a-button-group>
                            <a-button @click="getWxusers" type="primary">
                                搜索
                            </a-button>
                            <a-button @click="clearAndgetWxusers">
                                清空
                            </a-button>
                        </a-button-group>

                    </a-form-item>
                </a-form>
                
            </div>
        </a-card>

        <a-table size="middle" :data-source="wxusers.data" :columns="columns" rowKey="id" bordered :pagination="false">
            <template slot="avatarUrl" slot-scope="text,record">
                <a-avatar :src="record.avatarUrl" />
            </template>

            <template slot="action" slot-scope="text,record">
                <a-button-group>
                    <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="handleDel(record)">
                        <a-button icon="delete" size="small" type="danger">
                            删除
                        </a-button>
                    </a-popconfirm>

                </a-button-group>
            </template>
        </a-table>
        <a-pagination style="margin-top:10px;" :total="wxusers.total" :show-total="total => `共 ${total} 条`"
            v-if="wxusers.total>wxusers.per_page" :defaultCurrent='1' :pageSize="wxusers.per_page" @change="getWxusers">
        </a-pagination>

        <a-modal v-model="visible" :width="600" title="行政村管理" ok-text="确认" cancel-text="取消" @cancel="onCancel"
            @ok="submit">
            <a-form-model :dialog-style="{ top: '0px' }" ref="ruleForm" layout="vertical" :model="subForm">
                <a-form-model-item label="行政村名称" prop="name">
                    <a-input v-model="subForm.name" placeholder="请填写行政村名称" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label="行政村名称ID" prop="vid">
                    <a-input v-model="subForm.vid" placeholder="请填写行政村名称ID" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>
<script>
const columns = [
    {
        title: 'id',
        key: 'id',
        dataIndex: 'id',
    },
    {
        title: '昵称',
        key: 'nickName',
        dataIndex: 'nickName',
    },
    {
        dataIndex: 'avatarUrl',
        key: 'avatarUrl',
        title: "头像",
        width: 150,
        scopedSlots: { customRender: 'avatarUrl' },
    },
    {
        title: '城市',
        key: 'city',
        dataIndex: 'city',
        align: 'city',
    },
    {
        title: '性别',
        key: 'gender',
        dataIndex: 'gender',
        align: 'center',
    },
    {
        title: '省份',
        key: 'province',
        dataIndex: 'province',
        align: 'center',
    },
    {
        title: '注册时间',
        key: 'created_at',
        dataIndex: 'created_at',
        align: 'center',
    },
    {
        title: '姓名',
        key: 'name',
        dataIndex: 'name',
        align: 'center',
    },
    {
        title: '手机号',
        key: 'mobile',
        dataIndex: 'mobile',
        align: 'center',
    },
    {
        title: '操作',
        key: 'action',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
];
export default {
    data() {
        return {
            wxusers: {},
            columns,
            name: '',
            nickName: "",
            mobile: '',
            subForm: {},
            visible: false,
            form: {},
            method: "post",
        };
    },
    created() {
        this.getWxusers()
    },
    methods: {
        async submit() {
            //提交数据
            console.log(this.subForm)
            let url = ''
            if (this.method == "put") {
                url = `admin/wxuser/${this.subForm.id}`
            } else {
                url = `admin/wxuser`
            }
            await this.axios[this.method](url, this.subForm).then(_ => {
                this.visible = false
                this.getWxusers()
            })
        },
        async clearAndgetWxusers() {
            this.name = '';
            this.nickName = ''
            this.mobile = ''
            await this.getWxusers()
        },
        onCancel() {
            this.$refs.ruleForm.resetFields();
            this.currWxuser = {}
        },
        async getWxusers(page = 1) {
            this.wxusers =
                await this.axios.get(`admin/wxuser?name=${this.name}&nickName=${this.nickName}&mobile=${this.mobile}&page=${page}`)
        },
        async handleEdit(record) {
            this.visible = true
            this.method = "put"
            this.$set(this.subForm, 'id', record.id)
            this.$set(this.subForm, 'vid', record.vid)
            this.$set(this.subForm, 'name', record.name)
        },
        async handleDel(record) {
            this.axios.delete(`admin/wxuser/${record.id}`).then(_ => {
                this.getWxusers()
            })
        },
        async addWxuser() {
            this.method = "post"
            this.visible = true;
        },

    }
};
</script>
  