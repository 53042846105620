<template>
  <div>
    <a-card>
      <div style="display: flex; justify-content: space-between">
        <a-form layout="inline">
          <a-form-item label="标题">
            <a-input v-model="title" placeholder="请输入标题关键字" />
          </a-form-item>
          <a-form-item label="阅读次数大于">
            <a-select
              style="width: 120px"
              :default-value="0"
              v-model="readedTimes"
            >
              <a-select-option
                :value="option"
                v-for="(option, index) in selectoptions"
                :key="index"
              >
                {{ option }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button-group>
              <a-button @click="getAllPolicies" type="primary"> 搜索 </a-button>
              <a-button @click="clearAndgetGetAllPolicies"> 清空 </a-button>
            </a-button-group>
          </a-form-item>
        </a-form>
        <a-button type="primary" @click="addPolicy">新增</a-button>
      </div>
    </a-card>
    <a-table
      size="middle"
      :data-source="policies.data"
      :columns="columns"
      rowKey="id"
      bordered
      :pagination="false"
    >
      <template slot="action" slot-scope="text, record">
        <a-button-group>
          <a-button
            icon="edit"
            size="small"
            type="primary"
            @click="handleEdit(record)"
          >
            修改
          </a-button>
          <a-popconfirm
            title="确定删除吗？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDel(record)"
          >
            <a-button icon="delete" size="small" type="danger"> 删除 </a-button>
          </a-popconfirm>
        </a-button-group>
      </template>
    </a-table>
    <a-pagination
      style="margin-top: 10px"
      :total="policies.total"
      :show-total="(total) => `共 ${total} 条`"
      v-if="policies.total > policies.per_page"
      :defaultCurrent="1"
      :pageSize="policies.per_page"
      @change="getAllPolicies"
    >
    </a-pagination>
  </div>
</template>

<script>
const columns = [
  {
    title: "id",
    key: "id",
    dataIndex: "id",
  },

  {
    title: "标题",
    key: "title",
    dataIndex: "title",
    width: 300,
  },

  {
    title: "创建时间",
    key: "created_at",
    dataIndex: "created_at",
    align: "center",
  },
  {
    title: "阅读次数",
    key: "readedTimes",
    dataIndex: "readedTimes",
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      policies: {},
      columns,
      selectoptions: [0, 100, 500, 1000, 5000, 10000, 20000, 50000],
      readedTimes: 0,
      title: "",
    };
  },
  created() {
    this.getAllPolicies();
  },
  methods: {
    async getAllPolicies(page = 1) {
      this.policies = await this.axios.get(
        `admin/policy?title=${this.title}&readedTimes=${this.readedTimes}&page=${page}`
      );
    },
    addPolicy() {
      this.$router.push({ path: "/admin/policy/create" });
    },
    handleEdit(record) {
      this.$router.push({ path: `/admin/policy/${record.id}/edit` });
    },
    async handleDel(record) {
      await this.axios.delete(`admin/policy/${record.id}`).then((_) => {
        this.policies.data.splice(
          this.policies.data.findIndex((item) => item.id == record.id),
          1
        );
      });
    },
    async clearAndgetGetAllPolicies() {
      this.readedTimes = 0;
      (this.title = ""), await this.getAllPolicies();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>