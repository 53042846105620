<template>
  <div>
    <a-card title="用户管理">
      <template #extra>
        <div style="display: flex;">
          <a-input-search enter-button placeholder="请输入姓名" @search="search" v-model="keyword"></a-input-search>
          <a-button type="primary" @click="addUser" style="margin-left:5px;">
            <a-icon type="plus"></a-icon>
            添加
          </a-button>
        </div>

      </template>
      <div>
        <a-table size="middle" :data-source="users.data" :columns="columns" rowKey="id" bordered :pagination="false">
          <template slot="action" slot-scope="text,record">
            <a-button-group>
              <a-button size="small" type="info" @click="handleEdit(record)">
                <a-icon type="form" />
                编辑
              </a-button>
              <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="handleDelete(record)">
                <a-button size="small" ghost type="danger">
                  <a-icon type="delete" />
                  删除
                </a-button>
              </a-popconfirm>
              <a-button size="small" ghost type="primary" @click="handle(record)">
                <a-icon type="safety" />
                角色
              </a-button>

            </a-button-group>
          </template>
        </a-table>
        <a-pagination v-if="users.total>users.per_page" :defaultCurrent='1' :total="users.total"
          :pageSize="users.per_page" @change="getUsers">
        </a-pagination>
      </div>
    </a-card>
    <a-drawer title="管理用户" :width="720" :visible="showRole" @close="closeRole">
      <UserRole v-if="showRole" :user="curUser" />
    </a-drawer>
    <!--        弹出-->

    <a-drawer title="管理用户" :width="720" :visible="visible" @close="onClose">

      <a-form-model :rules="rules" ref="ruleForm" :model="formState" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item label="用户名" required prop="name">
              <a-input style="width: 100%" v-model="formState.name" placeholder="请输入用户名" />
            </a-form-model-item>

          </a-col>
          <a-col :span="8">
            <a-form-model-item label="手机号" required prop="mobile">
              <a-input style="width: 100%" v-model="formState.mobile" placeholder="请输入手机号" />
            </a-form-model-item>
          </a-col>


          <a-col :span="8">
            <a-form-model-item label="密码" required prop="password">
              <a-input-password style="width: 100%" v-model="formState.password" placeholder="请输入密码" />
            </a-form-model-item>
          </a-col>


          <a-col :span="8">
            <a-form-model-item label="确认密码" required prop="password_confirmation">
              <a-input-password style="width: 100%" v-model="formState.password_confirmation" placeholder="请输入确认密码" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="邮箱" prop="email">
              <a-input style="width: 100%" v-model="formState.email" placeholder="请输入邮箱" />
            </a-form-model-item>
          </a-col>

        </a-row>
        <a-col :span="24">
          <a-button type="primary" @click="handleSubmit">
            提交
          </a-button>
          <a-button @click="onClose" style="margin-left:10px;">
            取消
          </a-button>
        </a-col>
      </a-form-model>

    </a-drawer>
  </div>

</template>

<script>
const rules = {
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },

  ],
  mobile: [{ required: true, message: '请输入手机号', trigger: 'change' },],
  password: [{ required: true, min: 6, message: '密码错误或少于6位', trigger: 'change' },],
  password_confirmation: [{ required: true, message: '密码错误或少于6位', min: 6, trigger: 'change' },],
  email: [{
    required: true,
    validator: (rule, value, callback) => {
      if (value == '' || value == undefined) {
        callback(new Error('请输入邮箱'));
      }
      //验证身份证
      if (!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value)) {
        callback(new Error('邮箱不合法'));
      }
      callback();
    }, trigger: 'change'
  }],
}
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 80,
    align: 'center'
  },
  {
    title: '用户名',
    dataIndex: 'name',

    key: 'name',
    width: 100,
    align: 'center'
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 100,
    align: 'center'
  },
  {
    title: '邮箱',
    dataIndex: 'email',

    key: 'email',
    width: 200,
    align: 'center'
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'action' }
  },
]
import UserRole from './components/UserRole.vue'
export default {
  components: { UserRole },
  data() {
    return {
      users: [],
      rules,
      columns,
      curUser: {},
      visible: false,
      keyword: '',
      showRole: false,
      method: "POST",
      formState: {
        id: "",
        name: "",
        mobile: "",
        password: '',
        password_confirmation: "",
        email: '',
      }
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers(page = 1) {
      this.users = await this.axios.get(`user?page=${page}`)
    },
    onClose() {
      this.visible = false
      this.$refs.ruleForm.resetFields();
    },
    closeRole() {
      this.showRole = false
    },
    addUser() {
      this.formState={}
      this.visible = true
      this.method = "post"
    },
    handleSubmit() {
      const method = this.method;
      let url = ""
      if (method == 'post') {
        url = "user"
      }
      if (method == "put") {
        url = `user/${this.formState.id}`
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await this.axios[method](url, this.formState).then(_ => {
            this.visible = false
            this.$refs.ruleForm.resetFields()
            this.getUsers();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    async search() {
      this.users = await this.axios.post('user/search', { keyword: this.keyword })
    },
    handle(record) {
      console.log(record)
      this.curUser = record
      this.showRole = true
    },
    async handleDelete(record) {
      await this.axios.delete(`user/${record.id}`).then(_ => {
        this.getUsers();
      })
    },
    handleEdit(record) {
      this.method = "put"
      this.visible = true
      this.$set(this.formState, 'id', record.id)
      this.$set(this.formState, 'name', record.name)
      this.$set(this.formState, 'mobile', record.mobile)
      this.$set(this.formState, 'email', record.email)
    }
  }
}
</script>

<style scoped>

</style>
