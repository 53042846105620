<template>
    <div>
        <a-row>
            <a-col :span="8">
                <a-card title="新增公告">
                    <a-form-model ref="ruleForm" :rules="rules" layout="vertical" :model="form">
                        <a-form-model-item label="公告内容" prop="content">
                            <a-textarea v-model="form.content" placeholder="标题"
                                :auto-size="{ minRows: 4, maxRows: 10 }" />
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button type="primary" @click="onSubmit">
                                提交
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </a-card>

            </a-col>
            <a-col :span="16">
                <a-card>
                    <a-form layout="inline">
                        <a-form-item label="关键字">
                            <a-input v-model="keyword" raw="8" placeholder="请输入内容关键字" />
                        </a-form-item>
                        <a-form-item label="状态">
                            <a-select style="width: 120px" :default-value="state" v-model="state">
                                <a-select-option value="true">
                                    已发布
                                </a-select-option>
                                <a-select-option value="false">
                                    未发布
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-button-group>
                                <a-button @click="getNotices" type="primary">
                                    搜索
                                </a-button>
                            </a-button-group>

                        </a-form-item>
                    </a-form>

                    <a-table size="small" :data-source="notices.data" :columns="columns" rowKey="id" bordered
                        :pagination="false">
                        <template slot="state" slot-scope="text,record">
                            <a-button-group>
                                <a-icon :style="{ fontSize: '18px',color:'#52c419'}" type="check-circle"
                                    two-tone-color="#52c41a" theme="filled" v-if="record.state==1" />
                                <a-icon :style="{ fontSize: '18px',color:'#f8ad13'}" type="info-circle"
                                    two-tone-color="#ffb142" theme="filled" v-else />
                            </a-button-group>
                        </template>
                        <template slot="toggle" slot-scope="text,record">
                            <a-button-group>
                                <a-button v-if="record.state==true" size="small" type="primary"
                                    @click="handleToggle(record)">
                                    取消发布
                                </a-button>
                                <a-button v-else size="small" type="danger" @click="handleToggle(record)">
                                    发布
                                </a-button>
                            </a-button-group>
                        </template>
                        <template slot="action" slot-scope="text,record">
                            <a-button-group>
                                <a-button icon="edit" size="small" type="primary" @click="handleEdit(record)">
                                    编辑
                                </a-button>
                                <a-popconfirm title="确定删除吗？" ok-text="是" cancel-text="否" @confirm="handleDel(record)">
                                    <a-button icon="delete" size="small" type="danger">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </a-button-group>
                        </template>
                    </a-table>
                    <a-pagination style="margin-top:10px;" :total="notices.total" :show-total="total => `共 ${total} 条`"
                        v-if="notices.total>notices.per_page" :defaultCurrent='1' :pageSize="notices.per_page"
                        @change="getNotices">
                    </a-pagination>
                </a-card>
            </a-col>
        </a-row>

        <!-- 模态框 -->
        <a-modal v-model="visible" :width="900" :footer="null">
            <a-form-model ref="ruleEditForm" :rules="rules" layout="vertical" :model="editForm">
                <a-form-model-item label="公告内容" prop="content">
                    <a-textarea v-model="editForm.content" placeholder="标题" :auto-size="{ minRows: 4, maxRows: 10 }" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" @click="updateSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>
<script>
import Upload from '@/components/Upload'


const columns = [
    {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
    },
    {
        title: '内容',
        key: 'content',
        dataIndex: 'content',
    },

    {
        title: '创建时间',
        key: 'created_at',
        dataIndex: 'created_at',
        align: 'center',
    },
    {
        title: '发布状态',
        key: 'state',
        dataIndex: 'state',
        align: 'center',
        scopedSlots: { customRender: 'state' },
    },
    {
        title: '修改状态',
        key: 'toggle',
        dataIndex: 'toggle',
        scopedSlots: { customRender: 'toggle' },
    },
    {
        title: "操作",
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' },
    }
];
const rules = {
    content: [
        { required: true, message: '内容不能为空', trigger: 'change' },
    ],
}
export default {
    components: { Upload },
    data() {
        return {
            rules,
            form: {},
            editForm: {
                id: null,
                content: null,
            },
            notices: {},
            columns,
            visible: false,
            keyword: "",
            state: "true",
        };
    },
    created() {
        this.getNotices()
    },
    methods: {
        async getNotices(page = 1) {
            console.log(this.state)
            this.notices = await this.axios.get(`admin/notice/?keyword=${this.keyword}&state=${this.state}&page=${page}`)
        },
        async onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    await this.axios.post("admin/notice", this.form).then(_ => {
                        this.$refs.ruleForm.resetFields();
                        this.getNotices()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        async updateSubmit() {
            this.$refs.ruleEditForm.validate(async valid => {
                if (valid) {
                    await this.axios.put(`admin/notice/${this.editForm.id}`, this.editForm).then(_ => {
                        this.getNotices()
                        this.visible = false
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async handleDel(c) {
            await this.axios.delete(`admin/notice/${c.id}`).then(() => {
                this.notices.data.splice(this.notices.data.indexOf(c), 1)
            })
        },
        handleEdit(record) {
            this.visible = true
            this.$set(this.editForm, 'id', record.id)
            this.$set(this.editForm, 'content', record.content)
        },
        async handleToggle(record) {
            await this.axios.post(`admin/notice/${record.id}/toggle`).then(_ => {
                this.getNotices()
            })
        }
    },
};
</script>
<style scoped>
/* For demo */
.ant-notice>>>.slick-dots {
    height: auto;
}

.ant-notice>>>.slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
    max-width: 80%;
}

.ant-notice>>>.slick-thumb {
    bottom: -45px;
}

.ant-notice>>>.slick-thumb li {
    width: 60px;
    height: 45px;
}

.ant-notice>>>.slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}

.ant-notice>>>.slick-thumb li.slick-active img {
    filter: grayscale(0%);
}
</style>